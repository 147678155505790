import React from 'react';
import styled from 'styled-components';
import { URL_MLNTNC_AMAZON_MUSIC, URL_MLNTNC_APPLE_MUSIC, URL_MLNTNC_SPOTIFY } from '../utils/constants';

const Container = styled.ul`
  margin-bottom: 0;

  & a {
    color: #7e7e7e;
    opacity: 1;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }
`;

const SocialIcons = ({}) => {
  return (
    <Container className='icons'>
      <li>
        <a target='_blank' href='https://www.instagram.com/melantonicmusic' className='icon fa-instagram'>
          <span className='label'>Instagram</span>
        </a>
      </li>
      <li>
        <a target='_blank' href='https://www.facebook.com/melantonicmusic' className='icon fa-facebook'>
          <span className='label'>Facebook</span>
        </a>
      </li>
      <li>
        <a target='_blank' href={URL_MLNTNC_SPOTIFY} className='icon fa-spotify'>
          <span className='label'>Spotify</span>
        </a>
      </li>
      <li>
        <a target='_blank' href='https://www.youtube.com/c/MELANTONIC' className='icon fa-youtube'>
          <span className='label'>YouTube</span>
        </a>
      </li>
      <li>
        <a target='_blank' href={URL_MLNTNC_APPLE_MUSIC} className='icon fa-apple'>
          <span className='label'>Apple</span>
        </a>
      </li>
      <li>
        <a target='_blank' href={URL_MLNTNC_AMAZON_MUSIC} className='icon fa-amazon'>
          <span className='label'>Amazon</span>
        </a>
      </li>
    </Container>
  );
};

export default SocialIcons;
