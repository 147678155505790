export const URL_MLNTNC_SPOTIFY = 'https://open.spotify.com/artist/5e98nqrqo6jVDVCmy2NcT9?si=9QeNYaaDQUyOn572eOAqvw';
export const URL_MLNTNC_AMAZON_MUSIC =
  'https://www.amazon.de/s/ref=ntt_srch_drd_B077NW2DZW?ie=UTF8&field-keywords=MELANTONIC&index=digital-music&search-type=ss';
export const URL_MLNTNC_APPLE_MUSIC = 'https://itunes.apple.com/de/artist/melantonic/1317062704';

export const GIGS = [
  // {
  //   date: '2023',
  //   name: 'Friedensfestival',
  //   location: 'Iserlohn',
  // },
  // {
  //   date: '2023',
  //   name: 'Frotheim Open Air',
  //   location: 'Frotheim',
  // },
];
