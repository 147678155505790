import React, { Component } from 'react';

class NotFound extends Component {
  render() {
    return (
      <div className='imprint'>
        <h2>404 - Seite nicht gefunden</h2>
        <h3>404 - Page not found</h3>
      </div>
    );
  }
}

export default NotFound;
