import React from 'react';
import styled from 'styled-components';

import '../styles/main.scss';

import CinegateThumb from '../assets/images/thumbs/cinegate_studio_melantonic.png';
import CinegateFull from '../assets/images/fulls/cinegate_studio_melantonic.jpg';
import FuldaFull from '../assets/images/fulls/fulda_melantonic.jpg';
import FuldaThumb from '../assets/images/thumbs/fulda_melantonic.jpg';
import HafenFull from '../assets/images/fulls/hafen_melantonic_filter.jpg';
import HafenThumb from '../assets/images/thumbs/hafen_melantonic_filter.png';
import KnustVerbeugungFrontFull from '../assets/images/fulls/knust_melantonic_verbeugung_front.jpg';
import KnustVerbeugungFrontThumb from '../assets/images/thumbs/knust_melantonic_verbeugung_front.jpg';
import KnustVerbeugungBackFull from '../assets/images/fulls/knust_melantonic_verbeugung_back.jpg';
import KnustVerbeugungBackThumb from '../assets/images/thumbs/knust_melantonic_verbeugung_back.jpg';
import KnustOrangeFull from '../assets/images/fulls/knust_melantonic_orange.jpg';
import KnustOrangeThumb from '../assets/images/thumbs/knust_melantonic_orange.jpg';
import KnustLilaFull from '../assets/images/fulls/knust_melantonic_lila.jpeg';
import KnustLilaThumb from '../assets/images/thumbs/knust_melantonic_lila.jpeg';
import KnustSticksFull from '../assets/images/fulls/knust_melantonic_sticks.jpg';
import KnustSticksThumb from '../assets/images/thumbs/knust_melantonic_sticks.jpg';
import KnustRobsenFull from '../assets/images/fulls/knust_robsen.jpeg';
import KnustRobsenThumb from '../assets/images/thumbs/knust_robsen.jpeg';
import KnustBackThumb from '../assets/images/thumbs/melantonic_knust_back_thumb.jpg';
import MarkthalleThumb from '../assets/images/thumbs/markthalle_thumbs_up_thumb.jpg';
import GelseThumb from '../assets/images/thumbs/gelsenkirchen_melantonic_thumb.jpg';
import KnustBack from '../assets/images/fulls/melantonic_knust_back.jpg';
import MarkthalleFull from '../assets/images/fulls/markthalle_thumbs_up.jpg';
import GelseFull from '../assets/images/fulls/gelsenkirchen_melantonic.jpg';
import IndraPoster from '../assets/images/fulls/indra-poster-melantonic.png';
import SocialIcons from '../components/SocialIcons';

import { URL_MLNTNC_AMAZON_MUSIC, URL_MLNTNC_APPLE_MUSIC, URL_MLNTNC_SPOTIFY, GIGS } from '../utils/constants';

const SpotifyHighLightWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 20px;
  margin-bottom: 20px;

  & iframe {
    height: 380px;
    width: 48%;
  }

  @media (max-width: 736px) {
    flex-direction: column;
    & iframe {
      width: 300px;
    }
  }
`;

const SpotifyHighLightTitle = styled.div`
  font-size: 2.2em;
  text-decoration: underline;
  margin-bottom: 20px;
`;

const SpotifyList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;

  & iframe {
    height: 90px;
    margin-bottom: 16px;
  }

  @media (max-width: 736px) {
    flex-direction: column;
    & iframe {
      /* width: 300px; */
    }
  }
`;

const VideoList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-direction: row;
  gap: 1em;

  & > div {
    flex: 1;
    min-width: 48%;
  }

  & iframe {
    /* margin: auto; */
    width: 100%;
    height: 26vw;

    @media (max-width: 736px) {
      height: 32vw;
    }

    @media (max-width: 440px) {
      height: 55vw;
    }

    /* @include breakpoint('>small') {
      height: 5vw;
    } */
  }
`;

const IconListWrapper = styled.ul`
  display: flex;
  font-size: 1.4em;
  align-self: center;
  // justify-content: center;
  padding-top: 12px;

  & li {
    margin: 0 10px;
    transition: all 0.2s ease-in-out;
    &:hover {
      opacity: 0.7;
    }
  }
`;

const MusicHeader = styled.header`
  display: flex;
  justify-content: space-between;
`;

function App() {
  return (
    <div className='App'>
      <header id='header'>
        <h1>MELANTONIC</h1>
        <SocialIcons />
        <input className='burger-check' id='burger-check' type='checkbox' />
        <label htmlFor='burger-check' className='burger'></label>
        <nav id='navigation1' className='navigation'>
          <ul>
            <li>
              <a href='#intro'>Intro</a>
            </li>
            <li>
              <a href='#latest-video'>Latest</a>
            </li>
            <li>
              <a href='#music'>Music</a>
            </li>
            <li>
              <a href='#video'>Video</a>
            </li>
            <li>
              <a href='#drink'>Drink</a>
            </li>
            <li>
              <a href='#work'>Photos</a>
            </li>
            <li>
              <a href='#contact'>Booking</a>
            </li>
          </ul>
        </nav>
      </header>
      <SocialIcons />
      {/*Intro*/}
      <section id='intro' className='main style1 dark fullscreen'>
        <div className='content'>
          <header>
            <h2>MELANTONIC</h2>
          </header>
          <footer>
            <a href='#latest-video' className='button style2 down'>
              More
            </a>
          </footer>
          {new Date() < new Date('2024-11-09') && (
            <div className='tickets'>
              <span>
                08.11.2024 -{' '}
                <a href={IndraPoster} target='_blank' rel='noreferrer'>
                  Indra Musikclub (eigene Show)
                </a>
              </span>
              <a
                className='tickets__link'
                href='mailto:booking@melantonic.de?subject=Ticketanfrage%2008.11.24%20Indra&body=Moin!%0D%0AKonzert%3A%20MELANTONIC%20-%20Indra%20-%2008.11.24%0D%0A%0D%0ABitte%20ausf%C3%BCllen%3A%0D%0ATicketanzahl%3A%200%0D%0AVorname%3A%20Max%0D%0ANachname%3A%20Musterfrau%0D%0A%0D%0Amailto:booking@melantonic.de?subject=Ticketanfrage%2008.11.24%20Indra&body=Moin%20Jungs!%0D%0ATicketanfrage%20f%C3%BCr%20MELANTONIC%20%40%20Indra%20-%2008.11.24%0D%0A%0D%0ABitte%20ausf%C3%BCllen%3A%0D%0A-%20Ticketanzahl%3A%200%0D%0A-%20Vorname%3A%20Max%0D%0A-%20Nachname%3A%20Musterfrau%0D%0A-%20Gesamtpreis%20(15%20%E2%82%AC%20pro%20Ticket)%3A%200%20%E2%82%AC%0D%0A%0D%0A(Zahlungsinformationen%20erhaltet%20ihr%20als%20Antwort%20auf%20diese%20Mail)%0D%0A%0D%0A%0D%0A'
              >
                Tickets
              </a>
            </div>
          )}
        </div>
      </section>
      <section id='latest-video' className='main style2 left dark fullscreen'>
        <div>
          <header>
            <h2>CLOSE YOUR EYES</h2>
          </header>
          <iframe
            title='Close Your Eyes Musivvideo'
            className='youtube-iframe'
            width='560'
            height='315'
            src='https://www.youtube.com/embed/1CLykSDSOAc'
            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          />
        </div>
        <div>
          <header>
            <h2>BEST FRIEND</h2>
          </header>
          <iframe
            title='Best Friend Musivvideo'
            className='youtube-iframe'
            width='560'
            height='315'
            src='https://www.youtube.com/embed/WysOjC1Q1Ak'
            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          />
        </div>
        <a href='#music' className='button style2 down anchored'>
          Next
        </a>
      </section>
      {/* One -->*/}
      <section id='music' className='main style2 center dark fullscreen'>
        <div className='content box style2'>
          <MusicHeader>
            <h2>Music</h2>
            <IconListWrapper>
              <li>
                <a target='_blank' href={URL_MLNTNC_SPOTIFY} className='icon fa-spotify' rel='noreferrer'>
                  <span className='label'>Spotify</span>
                </a>
              </li>
              <li>
                <a target='_blank' href={URL_MLNTNC_APPLE_MUSIC} className='icon fa-apple' rel='noreferrer'>
                  <span className='label'>Apple</span>
                </a>
              </li>
              <li>
                <a target='_blank' href={URL_MLNTNC_AMAZON_MUSIC} className='icon fa-amazon' rel='noreferrer'>
                  <span className='label'>Amazon</span>
                </a>
              </li>
            </IconListWrapper>
          </MusicHeader>
          {/*<img src={YDGI_Cover} alt="Album Cover You Don't Get It Single" />*/}

          {/*<AudioPlayer />*/}

          {/*        <div class="audio-wrapper">-->*/}
          {/*            <audio-->*/}
          {/*                    controls-->*/}
          {/*                    src="audio/You_Dont_Get_It.mp3">-->*/}
          {/*            </audio>-->*/}
          {/*            <audio-->*/}
          {/*                    controls-->*/}
          {/*                    src="audio/Grumpy_Dragon.mp3">-->*/}
          {/*            </audio>-->*/}
          {/*        </div>-->*/}

          <SpotifyHighLightWrapper>
            <SpotifyHighLightTitle>Aktuelle Single</SpotifyHighLightTitle>
            {/*Close Your Eyes*/}
            <iframe
              src='https://open.spotify.com/embed/track/5pTrsHjqC3NjML07q1NacL?utm_source=generator'
              width='100%'
              height='152'
              allowTransparency='true'
              allow='autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture'
              loading='lazy'
            />
          </SpotifyHighLightWrapper>

          <SpotifyList>
            {/*Best Friend*/}
            <iframe
              src='https://open.spotify.com/embed/track/2zyZLfnJybILPmzZe9BDYh'
              frameBorder='0'
              allowTransparency='true'
              allow='encrypted-media'
            />
            {/*Cage*/}
            <iframe
              src='https://open.spotify.com/embed/track/3oafveggm6zEhsX2TtBs3J'
              frameBorder='0'
              allowTransparency='true'
              allow='encrypted-media'
            />
            {/*Floating Buoy*/}
            <iframe
              src='https://open.spotify.com/embed/track/0SquFUM4qQDJFnxaUuUTrZ'
              frameBorder='0'
              allowTransparency='true'
              allow='encrypted-media'
            />
            {/*Everything in Line*/}
            <iframe
              src='https://open.spotify.com/embed/track/0UhulQVcF8JyhlLJPABnYg'
              frameBorder='0'
              allowTransparency='true'
              allow='encrypted-media'
            />
            {/*YDGI*/}
            <iframe
              src='https://open.spotify.com/embed/track/2hEJ8BRxwG7ngwuyHF3O1D'
              frameBorder='0'
              allowTransparency='true'
              allow='encrypted-media'
            />
            {/*GD*/}
            <iframe
              src='https://open.spotify.com/embed/track/022WbjVPANtVXV0HOZKPV5'
              frameBorder='0'
              allowTransparency='true'
              allow='encrypted-media'
            />
            {/*Arthurs Table*/}
            <iframe
              src='https://open.spotify.com/embed/track/08VDtlMQnlOdYPrFZHj8w0'
              frameBorder='0'
              allowTransparency='true'
              allow='encrypted-media'
            />
            {/*YJHTM*/}
            <iframe
              src='https://open.spotify.com/embed/track/65gGRHrf9QxM6yTPeGvYsl'
              frameBorder='0'
              allowTransparency='true'
              allow='encrypted-media'
            />
          </SpotifyList>
        </div>
        <a href='#video' className='button style2 down anchored'>
          Next
        </a>
      </section>
      {/*// Two -->*/}
      <section id='video' className=' style2 dark fullscreen'>
        <div className=' box style2'>
          <header>
            <h2>Musikvideos</h2>
          </header>
          <VideoList>
            <div>
              <iframe
                className='youtube-iframe'
                width='560'
                height='315'
                src='https://www.youtube.com/embed/1CLykSDSOAc'
                frameBorder='0'
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              />
            </div>
            <div>
              <iframe
                className='youtube-iframe'
                width='560'
                height='315'
                src='https://www.youtube.com/embed/T0oXquMe80U'
                frameBorder='0'
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              />
            </div>
            <div>
              <iframe
                className='youtube-iframe'
                width='560'
                height='315'
                src='https://www.youtube.com/embed/AMxBW9asBd4'
                frameBorder='0'
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              />
            </div>
            <div>
              <iframe
                className='youtube-iframe'
                width='560'
                height='315'
                src='https://www.youtube.com/embed/NpOHz1v8hK0'
                frameBorder='0'
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              />
            </div>
          </VideoList>
        </div>

        <a href='#work' className='button style2 down anchored'>
          Next
        </a>
      </section>

      {/*// Drink -->*/}

      <section id='drink' className='main style2 right dark fullscreen'>
        <div className='content box style2'>
          <header>
            <h2>Unser Drink</h2>
          </header>
          <iframe
            width='560'
            height='315'
            src='https://www.youtube.com/embed/JfxZ3_vX3lY'
            frameBorder='0'
            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          />
        </div>
        <a href='#work' className='button style2 down anchored'>
          Next
        </a>
      </section>

      {/* Work -->*/}

      <section id='work' className='main style3 primary'>
        <div className='content'>
          <header>
            <h2>Fotos</h2>
          </header>

          {/* Gallery  -->*/}

          <div className='gallery'>
            <article className='from-left'>
              <a href={KnustVerbeugungBackFull} className='image fit'>
                <img
                  src={KnustVerbeugungBackThumb}
                  title='MELANTONIC (eigene Show) Knust 2022'
                  alt='MELANTONIC (eigene Show) Knust 2022'
                />
              </a>
            </article>
            <article className='from-right'>
              <a href={KnustSticksFull} className='image fit'>
                <img src={KnustSticksThumb} title='Knust 2022 MELANTONIC' alt='MELANTONIC - im Knust Hamburg' />
              </a>
            </article>
            <article className='from-left'>
              <a href={CinegateFull} className='image fit'>
                <img src={CinegateThumb} title='' alt='' />
              </a>
            </article>
            <article className='from-right'>
              <a href={MarkthalleFull} className='image fit'>
                <img
                  src={MarkthalleThumb}
                  title='Eigenes Konzert in der Markthalle 2017 - ausverkauft'
                  alt='Eigenes Konzert MELANTONIC in der Markthalle 2017 - ausverkauft'
                />
              </a>
            </article>
            <article className='from-left'>
              <a href={KnustBack} className='image fit'>
                <img
                  src={KnustBackThumb}
                  title='Eigenes Konzert im Knust 2018 - ausverkauft - Foto: Hendrik Buchholz'
                  alt='Eigenes Konzert MELANTONIC im Knust in Hamburg 2018 - ausverkauft'
                />
              </a>
            </article>
            <article className='from-right'>
              <a href={GelseFull} className='image fit'>
                <img src={GelseThumb} title='Gelsenkirchen' alt='MELANTONIC in Gelsenkirchen Bismacker Rocktage 2018' />
              </a>
            </article>
            <article className='from-left'>
              <a href={HafenFull} className='image fit'>
                <img src={HafenThumb} title='MELANTONIC vor dem Hamburger Hafen' alt='' />
              </a>
            </article>
            <article className='from-right'>
              <a href={KnustVerbeugungFrontFull} className='image fit'>
                <img
                  src={KnustVerbeugungFrontThumb}
                  title='MELANTONIC (eigene Show) Knust 2022'
                  alt='MELANTONIC (eigene Show) Knust 2022'
                />
              </a>
            </article>
            <article className='from-left'>
              <a href={KnustLilaFull} className='image fit'>
                <img src={KnustLilaThumb} title='MELANTONIC ' alt='MELANTONIC Foto' />
              </a>
            </article>
            <article className='from-right'>
              <a href={FuldaFull} className='image fit'>
                <img src={FuldaThumb} title='MELANTONIC in Fulda' alt='MELANTONIC in Fulda' />
              </a>
            </article>
            <article className='from-left'>
              <a href={KnustOrangeFull} className='image fit'>
                <img
                  src={KnustOrangeThumb}
                  title='MELANTONIC (eigene Show) Knust 2022'
                  alt='MELANTONIC (eigene Show) Knust 2022'
                />
              </a>
            </article>
            <article className='from-right'>
              <a href={KnustRobsenFull} className='image fit'>
                <img src={KnustRobsenThumb} title='MELANTONIC Robsen am Rocken' alt='MELANTONIC Robsen am Rocken' />
              </a>
            </article>
          </div>

          {/* Instagram  -->*/}
          {/*<header>*/}
          {/*  <h2>#dieduennstebandderwelt</h2>*/}
          {/*  <p>Unsere letzten Videos & Fotos von Instagram.</p>*/}
          {/*</header>*/}
          {/*<div className='gallery small' id='gallery-instagram'></div>*/}
        </div>
      </section>

      {GIGS.length > 0 && (
        <section id='termine' className='main style3 secondary'>
          <div className='content'>
            <header>
              <h2>Termine</h2>
            </header>
            <div className='box'>
              {GIGS.map((gig) => (
                <div>
                  {gig.date} - {gig.name} ({gig.location})
                </div>
              ))}
            </div>
          </div>
        </section>
      )}

      {/* Contact -->*/}
      <section id='contact' className='main style3 secondary'>
        <div className='content'>
          <header>
            <h2>Sag moin moin!</h2>
          </header>
          <div className='box'>
            <h3>BOOKING</h3>
            <p>
              <a href='mailto:booking@melantonic.de'>booking@melantonic.de</a>
            </p>

            <h3>SONST</h3>
            <p>
              <a href='mailto:moin@melantonic.de'>moin@melantonic.de</a>
            </p>
          </div>
        </div>
      </section>
      {/* Footer -->*/}
      <footer id='footer'>
        <SocialIcons />

        {/* Menu -->*/}
        <ul className='menu'>
          <li>&copy; {new Date().getFullYear()} MELANTONIC</li>
          <li>
            <a rel='nofollow' href='/impressum.html'>
              Impressum
            </a>
          </li>
          <li>
            <a rel='nofollow' href='/datenschutz.html'>
              Datenschutz
            </a>
          </li>
        </ul>
      </footer>
    </div>
  );
}

export default App;
